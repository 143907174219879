import React from 'react';
import { useTranslation } from "react-i18next";
import {getLocalize} from "../../../i18n-helper";

const TaskWithActionComponent = ({subTask, taskTappedAction}) => {
    const { t, i18n } = useTranslation();

    function getDescription() {
        if (subTask.type === "adsgram") {
            return `+${subTask.rewardCount} $BUBBLE ${t('every_watch')}`
        }

        return `+${subTask.rewardCount} $BUBBLE`
    }

    return (
        <>
            <div className={'white-12 earn-item'} onClick={() => taskTappedAction(subTask)}>

                {subTask.type === "referral"
                    ? <div style={{marginRight: '3px'}}>
                        <div>Invited frens: {subTask.meta.referralCount}</div>
                        {/*<div className={'content-settings-section-earn-text'}>You earned 10% from balance each fren</div>*/}
                    </div>
                    : <div style={{marginRight: '3px'}}>
                        <div>{getLocalize(i18n, subTask.title)}</div>
                        <div className={'earn-item-description'}>{getDescription()}</div>
                        {subTask.type === "adsgram"
                            ? <>
                                <div className={'earn-item-description'}>
                                    {t('daily_limit')}: {subTask.meta.dailyRewardCount}/20. <br/>
                                    {t('total_views')}: {subTask.meta.totalCoins}
                                </div>
                            </>
                            : null
                        }
                    </div>
                }
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {subTask.type === "referral"
                        ? null
                        : <>
                            {subTask.status === "FINISHED"
                                ? <div className={'earn-item-button'} style={{opacity: '0.1'}}>✓</div>
                                : <div className={'earn-item-button'}>{subTask.isTouched ? t('check') : t('go')}</div>
                            }
                        </>
                    }

                </div>
            </div>
            {subTask.isLoading != null && subTask.isLoading
                ? <div className={'content-settings-section-earn-text'}>{t('loading')}...</div>
                : null
            }
            {subTask.success
                ? <div className={'content-settings-section-earn-text green'}>{t('success')}!</div>
                : null
            }
            {subTask.error != null
                ? <div className={'content-settings-section-earn-text red'}>{subTask.error}</div>
                : null
            }
        </>
    );
};

export default TaskWithActionComponent;
