import React from 'react';
import './modal.css';

// const Modal = ({children, visible, setVisible}) => {
const Modal = ({children, setVisible}) => {

    // console.log('visible = ', visible);

    return (
        // <div className={`modal ${visible ? "active" : ""}`}>
        <div className={`modal active`} onClick={() => setVisible(null)}>
            <div className={"modal-content"} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
