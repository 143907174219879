function getLocalize(i18n, key) {
    if (i18n.resolvedLanguage === "ru") {
        if (key === "Daily") {
            return "Ежедневные задания";
        } else if (key === "One-time") {
            return "Задания";
        } else if (key === "Watch ad to earn") {
            return "Посмотреть рекламу";
        } else if (key === "Visit web site Coin Bubbles") {
            return "Перейти на сайт Coin Bubbles";
        } else if (key === "Subscribe to channel Coin Bubbles") {
            return "Подписаться на tg канал Coin Bubbles";
        }
    }

    return key;
}

module.exports = {
    getLocalize
}
