import React from 'react';
import { useTranslation } from "react-i18next";

const LoadingComponent = ({isLoading, error}) => {
    const { t } = useTranslation();

    return (
        <div>
            {isLoading || error
                ? <div style={{textAlign: 'center'}}>
                    {isLoading
                        ? <div>{t('loading')}...</div>
                        : null
                    }

                    {error
                        ? <div>{t('error')}: {error}</div>
                        : null
                    }
                </div>
                : null
            }
        </div>
    );
};

export default LoadingComponent;
