import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';

import CryptocurrenciesPage from "../pages/cryptocurrencies/cryptocurrencies.page";
import EarnPage from "../pages/earn/earn.page";
import FrensPage from "../pages/frens/frens.page";

function App() {
  return (
      <Router>
        <Routes>
          <Route exact path='/' element={<CryptocurrenciesPage />} />
          <Route exact path='/earn' element={<EarnPage />} />
          <Route exact path='/frens' element={<FrensPage />} />
          <Route path="*" element={<CryptocurrenciesPage />}/>
        </Routes>
      </Router>
  );
}

export default App;
