// bubbleUtils.js

export const isCollision = (pos1, pos2, radius1, radius2) => {
    const dx = pos1.x - pos2.x;
    const dy = pos1.y - pos2.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance < (radius1 + radius2);
};

export const resolveCollision = (bubble1, bubble2, svgWidth, svgHeight) => {
    const dx = bubble1.position.x - bubble2.position.x;
    const dy = bubble1.position.y - bubble2.position.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    // Если расстояние равно нулю (пузыри в одном месте), выходим
    if (distance === 0) return;

    // Вычисляем перекрытие
    const overlap = 0.5 * (distance - bubble1.radius - bubble2.radius);

    // Изменение позиций для первого пузыря
    const b1xNew = (overlap * dx) / distance;
    const b1yNew = (overlap * dy) / distance;
    bubble1.position.x -= b1xNew;
    bubble1.position.y -= b1yNew;

    // Изменение позиций для второго пузыря
    const b2xNew = (overlap * dx) / distance;
    const b2yNew = (overlap * dy) / distance;
    bubble2.position.x += b2xNew;
    bubble2.position.y += b2yNew;

    // Убедимся, что пузырьки не выходят за границы SVG
    keepWithinBounds(bubble1, svgWidth, svgHeight);
    keepWithinBounds(bubble2, svgWidth, svgHeight);

    // Обмен скоростями
    const tempSpeedX = bubble1.speed.x;
    const tempSpeedY = bubble1.speed.y;
    bubble1.speed.x = bubble2.speed.x;
    bubble1.speed.y = bubble2.speed.y;
    bubble2.speed.x = tempSpeedX;
    bubble2.speed.y = tempSpeedY;
};

// Функция, которая не позволяет пузырькам выходить за границы SVG
const keepWithinBounds = (bubble, svgWidth, svgHeight) => {
    if (bubble.position.x - bubble.radius < 0) {
        bubble.position.x = bubble.radius;
    } else if (bubble.position.x + bubble.radius > svgWidth) {
        bubble.position.x = svgWidth - bubble.radius;
    }

    if (bubble.position.y - bubble.radius < 0) {
        bubble.position.y = bubble.radius;
    } else if (bubble.position.y + bubble.radius > svgHeight) {
        bubble.position.y = svgHeight - bubble.radius;
    }
};
