import React, {useEffect, useState} from 'react';
import BottomNavBarComponent from "../../component/bottom-nav-bar/bottom-nav-bar.component";
import CoinService from "../../service/coin.service";
import LoadingComponent from "../../component/loading/loading.component";
import { useTranslation } from "react-i18next";

const tg = window.Telegram.WebApp;

const FrensPage = () => {
    const userTelegramId = tg.initDataUnsafe.user?.id; // 226875807
    const { t } = useTranslation();

    const [frensData, setFrensData] = useState(null);
    const [frensIsLoading, setFrensIsLoading] = useState(false);
    const [frensError, setFrensError] = useState(null);

    const [referralLinkIsCopied, setReferralLinkIsCopied] = useState(false);
    const [referralLinkIsCopiedError, setReferralLinkIsCopiedError] = useState(null);

    async function copyReferralLink() {
        if (userTelegramId == null) {
            setReferralLinkIsCopiedError(t('work_only_in_telegram_version'));
            return;
        }
        setReferralLinkIsCopiedError(null);
        await navigator.clipboard.writeText(`https://t.me/coinbubbles_bot/bubbles?startapp=ref_${userTelegramId}`);
        setReferralLinkIsCopied(true)
    }

    useEffect(() => {
        getFrens();
    }, []);

    async function getFrens() {
        if (userTelegramId == null) {
            setFrensError(t('work_only_in_telegram_version'));
            return;
        }
        try {
            setFrensError(null);
            setFrensIsLoading(true);
            const response = await CoinService.getReferrals(userTelegramId);
            setFrensData({
                referralsCount: response.referralsCount,
                referrals: response.referrals,
                referUsername: response.referUsername,
            })
        } catch (e) {
            setFrensError(e.message);
        }
        setFrensIsLoading(false);
    }

    return (
        <div className={'page'}>
            <div className={'page-container'}>
                <div className={'mb-10'}>
                    <div className={'title mb-5'}>{t('frens')}:</div>
                    <LoadingComponent isLoading={frensIsLoading} error={frensError}/>

                    {frensData
                        ? <>
                            <div className={'mb-10'}>{t('you_invite_from')}: {frensData.referUsername ?? t('unowned')}</div>

                            <div style={{display: 'flex'}}>
                                <div className={'mb-10'} style={{
                                    backgroundColor: '#444444e6',
                                    fontSize: '12px',
                                    borderRadius: '5px',
                                    padding: '2px 10px'
                                }}>{t('invite_form_frens_tip')}</div>
                            </div>

                            <div className={'mb-10'}>{t('frens')} ({frensData.referralsCount}):</div>
                            {frensData.referrals.map((item, index) =>
                                <div key={index + 1} style={{display: 'flex', alignItems: 'center', borderBottom: '1px solid #6f6f6f', paddingBottom: '10px', marginBottom: '10px'}}>
                                    <div style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '6px',
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                    }}/>
                                    <div>{item.name != null ? item.name : `User ${index + 1}`}</div>
                                </div>
                            )}
                        </>
                        : null
                    }
                </div>
            </div>

            <BottomNavBarComponent activeLink={3} child={
                <>
                    <div style={{
                        padding: '5px 10px 0',
                        textAlign: 'center',
                        backgroundColor: '#222'
                    }}>
                        <button style={{marginBottom: '3px', width: '100%'}} className={`period-button cursor-pointer white text-black`} onClick={() => copyReferralLink()}>
                            {t('invite_a_fren')}!
                        </button>
                        {referralLinkIsCopied
                            ? <div className={'content-settings-section-earn-text green'}>{t('copied')}!</div>
                            : null
                        }
                        {referralLinkIsCopiedError != null
                            ? <div className={'content-settings-section-earn-text red'}>{referralLinkIsCopiedError}</div>
                            : null
                        }
                    </div>
                </>
            }/>
        </div>
    );
};

export default FrensPage;
