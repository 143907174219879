import {Constants} from "../constants/constants";

class CoinService {
    static async onInitUser(userTelegramId, username, startParam) {
        try {
            const jsonData = {
                "userTelegramId": userTelegramId,
                "username": username,
                "startParam": startParam,
            };
            const response = await fetch(Constants.baseUrl + `/api/v1/users/onInit`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(jsonData)
                }
            );
            return await response.json();
        } catch (e) {
            throw e;
        }
    }

    static async getCoinList(providerType, userTelegramId) {
        try {
            let queryParameters = `providerType=${providerType}`;
            if (userTelegramId != null) {
                queryParameters += `&userTelegramId=${userTelegramId}`;
            }
            const response = await fetch(Constants.baseUrl + `/api/v1/coins?${queryParameters}`)
            // const response = await fetch("https://cryptobubbles.net/backend/data/bubbles1000.usd.json")
            return await response.json();
        } catch (e) {
            throw e;
        }
    }

    static async getCoinChart(providerType, coinId, period, currency) {
        try {
            const queryParameters = `providerType=${providerType}&coinId=${coinId}&period=${period}&currency=${currency}`;
            const response = await fetch(Constants.baseUrl + `/api/v1/chart?${queryParameters}`)

            // const response = await fetch(`https://cryptobubbles.net/backend/data/charts/${period.toLowerCase()}/${coinId}/USD.json`)
            const responseJson = await response.json();
            return responseJson.data;
        } catch (e) {
            throw e;
        }
    }

    static async loadImage(providerType, coinId) {
        try {
            const imageurl = Constants.imageBaseUrl + `?providerType=${providerType}&coinId=${coinId}`// + bubble.image;
            const response = await fetch(imageurl, {
                method: "GET",
            });
            if (response.ok) {
                const imageBlob = await response.blob();
                return URL.createObjectURL(imageBlob);
            }
            return null;
        } catch (e) {
            throw e;
        }
    }

    static async getTasks(userTelegramId) {
        try {
            const jsonData = {
                "userTelegramId": userTelegramId,
            };
            const response = await fetch(Constants.baseUrl + `/api/v1/task`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(jsonData)
                }
            );
            return await response.json();
        } catch (e) {
            throw e;
        }
    }

    static async verifyTask(userTelegramId, taskId) {
        try {
            const jsonData = {
                "userTelegramId": userTelegramId,
                "taskId": taskId,
            };
            const response = await fetch(Constants.baseUrl + `/api/v1/task/checkIsCompleted`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(jsonData)
                }
            );
            return await response.json();
        } catch (e) {
            throw e;
        }
    }

    static async getReferrals(userTelegramId) {
        try {
            const jsonData = {
                "userTelegramId": userTelegramId,
            };
            const response = await fetch(Constants.baseUrl + `/api/v1/users/referrals`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(jsonData)
                }
            );
            // const response = await fetch(`https://cryptobubbles.net/backend/data/charts/${period.toLowerCase()}/${coinId}/USD.json`)
            return await response.json();
        } catch (e) {
            throw e;
        }
    }
}
export default CoinService;
