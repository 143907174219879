import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                unowned: "unowned",
                home: "Home",
                earn: "Earn",
                frens: "Frens",

                loading: "Loading",
                error: "Error",
                success: "Success",

                price: "Price",
                market_cap: "Market Cap",
                volume24: "24h Volume",
                date: "Date",
                hour: "hour",
                day: "day",
                week: "week",
                month: "month",
                year: "year",

                lang: "Lang",
                type: "Type",
                coinmarketcap: "Coinmarketcap",
                ton: "Ton",
                period: "Period",
                pages: "Pages",
                app_info: "App info",

                every_watch: "for every watch",
                daily_limit: "Daily limit",
                total_views: "Total views",
                check: "Check",
                go: "Go",
                work_only_in_telegram_version: "Work only in telegram version",
                adsgram_script_not_loaded: "Adsgram script not loaded",
                subscribe_error: "Subscribe error",
                you_watch_max_count_today: "You watch max count today",
                invite_form_frens_tip: "When distributing AirDrop you'll get 15% from frens balances",
                airdrop_description: "Airdrop description",
                you_earn: "You earn",

                you_invite_from: "You Invite from",
                invite_a_fren: "Invite a fren",
                copied: "Copied",
            }
        },
        ru: {
            translation: {
                unowned: "unowned",
                home: "Главная",
                earn: "Доход",
                frens: "Друзья",

                loading: "Загрузка",
                error: "Ошибка",
                success: "Успех",

                price: "Цена",
                market_cap: "Market Cap",
                volume24: "24h Volume",
                date: "Дата",
                hour: "час",
                day: "день",
                week: "неделя",
                month: "месяц",
                year: "год",

                lang: "Язык",
                type: "Тип",
                coinmarketcap: "Coinmarketcap",
                ton: "Ton",
                period: "Период",
                pages: "Страница",
                app_info: "App info",

                every_watch: "за каждый просмотр",
                daily_limit: "Ежедневный лимит",
                total_views: "Всего просмотров",
                check: "Проверить",
                go: "Старт",
                work_only_in_telegram_version: "Работает только в телеграмм",
                adsgram_script_not_loaded: "Adsgram скрипт не загружен",
                subscribe_error: "Ошибка подписки",
                you_watch_max_count_today: "На сегодня достигнут лимит просмотров",
                invite_form_frens_tip: "При распределении AirDrop вы получите 15% от балансов ваших друзей",
                airdrop_description: "Airdrop описание",
                you_earn: "Ваш баланс",

                you_invite_from: "Вас пригласил",
                invite_a_fren: "Пригласить друга",
                copied: "Скопировано",
            }
        },
    },
    lng: "ru",
    fallbackLng: "ru"
});

export default i18n;
