const AppType = {
    CMC: "CMC",
    // TON: "TON",
    DYOR: "DYOR"
}

const Period = {
    HOUR: "HOUR",
    DAY: "DAY",
    WEEK: "WEEK",
    MONTH: "MONTH",
    YEAR: "YEAR"
}

class CoinListItem {
    id;
    image;
    name;
    symbol;
    performance;
}

module.exports = {
    AppType,
    Period,
    CoinListItem,
}
